<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
        <Header
            :meta="meta"
            :page-title="moduleConfig.page_title"
            :page-image="moduleConfig.page_image"
            :page-kv-image="moduleConfig.page_kv_image"
            :header-section="moduleConfig.header_section"
        />
        <Tabs :tabs="tabs" :currentTab="currentTab" v-if="!isIndividualTab && tabs.map(x => x.path).some(x=> $route.path.indexOf(x)>=0)" />
        <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import themeColor from "@/mixins/liff/themeColor";
import module from "@/mixins/liff/coupon/main";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import _ from "lodash";

export default {
  mixins: [themeColor,module],
  components: {
    Header,
    Tabs
  },
  computed: {
    tabs: function () {
      return _.sortBy(this.moduleConfig.data_source, "order");
    },
    currentTab: function () {
      return this.$route.path.split('/').reverse()[0];
    },
    isIndividualTab () {
      return this.$route.name.match(/.*Tab$/)
    },
  },
  data() {
    return {
      themeConfigPage: "coupon",
    };
  },
  created() {},
  mounted() {
    if (this.$route.name == "LiffProjAbbottAndCoupon") {
      const isEndWithSlash = this.$route.path.slice(-1) == "/";
      const path = isEndWithSlash
        ? this.tabs.sort((a,b)=> a.order- b.order )[0].path
        : `${this.$route.path}/${this.tabs.sort((a,b)=> a.order- b.order )[0].path}`;
      this.$router.push({ path });
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/css/liff/main.css";
@import "../../../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
</style>
